'use strict';

/**
 * Initialize angular UI router for advisor dashboard.
 *
 * @param  {Object} $stateProvider API used to declare application states.
 */

angular
  .module('routes.interview', [
    'templates',
    'ui.router',
    'controller.interview',
    'controller.interview.goal',
    'controller.interview.risk',
    'controller.interview.portfolio-preferences',
    'controller.interview.account-credentials',
    'model.ContributionAmount',
    'model.AdvisorCreateNewClientFlow',
    'model.TrialRiskAppetite',
    'routes.interview.personal',
    'service.adaptive-views',
    'service.branding-machine',
    'service.marketing',
    'service.promotion-redemption-cookie-creator',
    'ngCookies',
  ])
  .config([
    '$stateProvider',
    '$urlRouterProvider',
    'adaptiveViewsProvider',
    'brandingMachineProvider',
    'config',
    configRoutes,
  ]);

function configRoutes(
  $stateProvider,
  $urlRouterProvider,
  adaptiveViewsProvider,
  brandingMachineProvider,
  config
) {
  function routeToGoal($state) {
    $state.transitionTo('interview.goal');
  }

  function routeToPortfolioOptions($state) {
    $state.transitionTo('interview.options');
  }

  function resolveAccountTypeRecommendations($http, storedUser, storedGoal) {
    var request = {
      goalLabel: storedGoal.label(),
      age: storedUser.age(),
    };

    return $http
      .get('api/accounts/types_recommendation.json', {
        params: _.underscoreKeys(request),
      })
      .then(function (recommendationResponse) {
        return recommendationResponse.data;
      });
  }

  function resolveStoredContributionAmount(ContributionAmount) {
    return ContributionAmount.findOrCreate(
      {},
      {
        resource: 'cookie',
        cache: false,
      }
    );
  }

  function resolveClientFlow(AdvisorCreateNewClientFlow, currentUser) {
    if (!currentUser) {
      return null;
    }
    return AdvisorCreateNewClientFlow.where({
      clientId: currentUser.person().id,
    }).then(function (list) {
      if (list.length === 0) {
        return null;
      }
      return _.first(list);
    });
  }

  function resolveCompletedRiskAppetiteQuizzes(CompletedRiskAppetiteQuiz, currentUser) {
    if (currentUser) {
      return CompletedRiskAppetiteQuiz.where({});
    } else {
      return CompletedRiskAppetiteQuiz.where(
        {},
        {
          resource: 'cookie',
          cache: false,
        }
      );
    }
  }

  function resolvePortfolioPreference(currentUser, PortfolioPreferenceAnswer) {
    const $cookies = angular.injector(['ngCookies']).get('$cookies');
    const cookie = $cookies.getObject('portfolio_preference_answers');
    const lastAccountForCurrentUser = currentUser?.accounts()?.slice(-1)?.[0]; // jshint ignore:line
    if (lastAccountForCurrentUser && !cookie) {
      const accountId = lastAccountForCurrentUser.id;
      const previousInvestmentPreferenceId = lastAccountForCurrentUser.investmentPreferenceId();
      return PortfolioPreferenceAnswer.find(
        {
          accountId,
          portfolioPreferenceId: previousInvestmentPreferenceId,
        },
        {
          force: true,
        }
      ).then((result) => result ?? PortfolioPreferenceAnswer.new()); // jshint ignore:line
    }
    const resources = {
      resource: 'cookie',
      cache: false,
    };
    return PortfolioPreferenceAnswer.findOrCreate({}, resources);
  }

  function resolveCurrentAccount() {
    const $cookies = angular.injector(['ngCookies']).get('$cookies');
    const objects = $cookies.getObject('accounts');
    const len = objects?.length;
    if (!len || !objects || len === 0) {
      return null;
    }
    const accountTypeId = objects[len - 1]?.typeId;
    return config.types.Account[accountTypeId]?.label;
  }

  function resolveStoredTrialRiskAppetite(TrialRiskAppetite) {
    return TrialRiskAppetite.findOrCreate(
      {},
      {
        resource: 'cookie',
        cache: false,
      }
    );
  }

  function resolveAdvisorReferralDetails(currentUser, clientFlow) {
    return brandingMachineProvider.getAdvisorDetails(
      currentUser,
      clientFlow && clientFlow.advisorReferralCode()
    );
  }

  function saveParamsToCookie($stateParams, storedUser, storedGoal, storedAccount) {
    var age = $stateParams.age;
    var goalType = $stateParams.goalType;
    var accountType = $stateParams.accountType;
    var responsible = $stateParams.responsible;

    if (age) {
      storedUser.save({
        age,
      });
    }

    if (goalType) {
      storedGoal.save({
        preset: goalType,
      });
    }

    if (accountType) {
      storedAccount.save({
        typeId: config.types.Account.findByName(accountType).id,
      });
    }

    // PM_todo - The next block that refers to "socially_responsible_portfolio" and "core_portfolio" is part
    //           of an old facility built into the marketing site.  It doesn't work with the new advisor
    //           workflows we are building.  Need to re-visit and take it out.
    if (responsible === 'true') {
      storedAccount.save({
        portfolioOptionId: _.findWhere(config.types.AccountPortfolioOption, {
          name: 'socially_responsible_portfolio',
        }).id,
      });
    } else if (responsible === 'false') {
      storedAccount.save({
        portfolioOptionId: _.findWhere(config.types.AccountPortfolioOption, {
          name: 'core_portfolio',
        }).id,
      });
    }

    storedGoal.timeframe(null);
    return true;
  }

  function routeToServiceOptions($location, $state) {
    $state.transitionTo('interview.service-options', $location.search(), {
      location: 'replace',
    });
  }

  function routeToDisclosures($cookies, $location, $state, currentUser) {
    const groupUrlIdCookie = $cookies.getObject('group_url_id');

    if (!groupUrlIdCookie) {
      return;
    }

    const isExternalAdvisorReferral = !groupUrlIdCookie.urlId.startsWith('mapersonal');

    if (
      !currentUser &&
      isExternalAdvisorReferral &&
      !$cookies.getObject('acknowledged_advisor_referral_disclosures')
    ) {
      $state.transitionTo('interview.advisor-referral-disclosures', $location.search(), {
        location: 'replace',
      });
    }
  }

  function resolveRedirectWhenLoggedIn($location, $state, currentUser) {
    if (currentUser) {
      $state.transitionTo('interview.goal', $location.search(), {
        location: 'replace',
      });
    }
  }

  function resolveRedirectToInterviewPlatform($location, $q, $state, config) {
    const platform = config.env.platform;

    if (platform && platform.name === 'WSI') {
      // From https://stackoverflow.com/a/30592506 Gustav's comment
      // The last `return $q.reject()` was not used as it seems to work without it.
      return $q.reject().catch(function () {
        $state.transitionTo('wsi-interview.goal', $location.search(), {
          location: 'replace',
        });
      });
    }
  }

  function resolveRedirectToReferralOnly(
    $location,
    $q,
    $state,
    currentUser,
    promotionRedemptionCookieCreator
  ) {
    if (!currentUser && !promotionRedemptionCookieCreator.existingCookie()) {
      // From https://stackoverflow.com/a/30592506 Gustav's comment
      // The last `return $q.reject()` was not used as it seems to work without it.
      return $q.reject().catch(function () {
        $state.transitionTo('referral-only', $location.search(), {
          location: 'replace',
        });
      });
    }
  }

  function resolvePreferredPortfolioManagerId($cookies) {
    return $cookies.getObject('group_url_id')?.portfolioManagerId;
  }

  $urlRouterProvider.when('/interview/1', ['$state', routeToGoal]);
  $urlRouterProvider.when('/interview/2', ['$state', routeToGoal]);
  $urlRouterProvider.when('/interview/3', ['$state', routeToGoal]);
  $urlRouterProvider.when('/interview/4', ['$state', routeToGoal]);
  $urlRouterProvider.when('/interview/5', ['$state', routeToGoal]);
  $urlRouterProvider.when('/interview/6', ['$state', routeToGoal]);
  $urlRouterProvider.when('/interview/7', ['$state', routeToGoal]);
  $urlRouterProvider.when('/interview/investment', ['$state', routeToPortfolioOptions]);

  $urlRouterProvider.when('/interview', ['$state', routeToGoal]);

  $urlRouterProvider.when('/interview/start', ['$location', '$state', routeToServiceOptions]);

  $stateProvider
    .state('interview', {
      url: '/interview',
      parent: 'site',
      hasMobileView: true,
      params: {
        preselectResponsiblePortfolioOption: false,
        preselectTrial: false,
      },
      views: {
        main: {
          controller: 'InterviewCtrl',
        },
        navbar: {
          templateUrl: 'navbar/interview.html',
        },
        footer: {
          templateUrl: adaptiveViewsProvider.isMobile()
            ? 'mobile/partials/footer.html'
            : 'partials/footer-minimal.html',
        },
      },
      abstract: true,
      resolve: {
        clientFlow: ['AdvisorCreateNewClientFlow', 'currentUser', resolveClientFlow],
        promotionRedemptionCookieCreator: 'promotionRedemptionCookieCreator',
        redirectToInterviewPlatform: [
          '$location',
          '$q',
          '$state',
          'config',
          resolveRedirectToInterviewPlatform,
        ],
        resolveRedirectToReferralOnly: [
          '$location',
          '$q',
          '$state',
          'currentUser',
          'promotionRedemptionCookieCreator',
          resolveRedirectToReferralOnly,
        ],
        advisorReferralDetails: ['currentUser', 'clientFlow', resolveAdvisorReferralDetails],
        preferredPortfolioManagerId: ['$cookies', resolvePreferredPortfolioManagerId],
      },
    })
    .state('interview.service-options', {
      url: '/service-options?age&goalType&accountType&responsible',
      controller: 'InterviewServiceOptionsController',
      data: {
        progress: 4,
        last: 'interview.service-options',
        next: 'interview.goal',
        step: 1,
        title: 'Interview - Service Options',
        header: 'Choose your desired service',
        interviewStep: 1,
      },
      resolve: {
        redirectWhenLoggedIn: ['$location', '$state', 'currentUser', resolveRedirectWhenLoggedIn],
      },
    })
    .state('interview.advisor-referral-disclosures', {
      url: '/referral-disclosure?age&goalType&accountType&responsible',
      controller: 'InterviewAdvisorReferralDisclosuresController',
      data: {
        progress: 4,
        last: 'interview.advisor-referral-disclosures',
        next: 'interview.goal',
        step: 1,
        title: 'Interview - Referral disclosure',
        header: 'Referral disclosure',
        interviewStep: 1,
      },
      resolve: {
        cookieSaved: [
          '$stateParams',
          'storedUser',
          'storedGoal',
          'storedAccount',
          saveParamsToCookie,
        ],
      },
    })
    .state('interview.goal', {
      url: '/goal?age&goalType&accountType&responsible',
      controller: 'InterviewGoalController',
      data: {
        progress: 8,
        last: 'interview.goal',
        next: 'interview.account-type',
        step: 1,
        title: 'Interview - Goal',
        header: 'Tell us about your investment goal',
        interviewStep: 1,
      },
      resolve: {
        cookieSaved: [
          '$stateParams',
          'storedUser',
          'storedGoal',
          'storedAccount',
          saveParamsToCookie,
        ],
        routeToDisclosures: ['$cookies', '$location', '$state', 'currentUser', routeToDisclosures],
      },
    })
    .state('interview.account-type', {
      url: '/account-type',
      controller: 'InterviewAccountTypeCtrl',
      hasMobileView: true,
      data: {
        progress: 16,
        last: 'interview.goal',
        next: 'interview.spousal-rrsp',
        step: 2,
        title: 'Interview - Account Type',
        header: 'Select your account type',
        interviewStep: 1,
      },
      resolve: {
        recommendations: ['$http', 'storedUser', 'storedGoal', resolveAccountTypeRecommendations],
        clientFlow: ['AdvisorCreateNewClientFlow', 'currentUser', resolveClientFlow],
      },
    })
    .state('interview.spousal-rrsp', {
      url: '/spousal-rrsp',
      controller: 'InterviewSpousalRrspCtrl',
      data: {
        progress: 16,
        last: 'interview.account-type',
        next: 'interview.financial',
        step: 3,
        title: 'Interview - Spousal RRSP',
        header: 'Tell us about your Spousal RRSP',
        interviewStep: 1,
      },
    })
    .state('interview.financial', {
      url: '/financial',
      controller: 'InterviewFinancialController',
      data: {
        progress: 24,
        last: 'interview.spousal-rrsp',
        next: 'interview.contribution-amount',
        step: 3,
        title: 'Interview - Financial Situation',
        header: 'Tell us about your financial situation',
        interviewStep: 1,
      },
    })
    .state('interview.contribution-amount', {
      url: '/contribution-amount',
      controller: 'InterviewContributionAmountController',
      data: {
        progress: 24,
        last: 'interview.financial',
        next: 'interview.risk',
        step: 4,
        title: 'Interview - Contribution Amount',
        header: 'Let us know about your Group Savings Plan contributions',
        interviewStep: 1,
      },
      resolve: {
        storedContributionAmount: ['ContributionAmount', resolveStoredContributionAmount],
      },
    })
    .state('interview.risk', {
      url: '/risk-appetite',
      controller: 'InterviewRiskController',
      hasMobileView: true,
      data: {
        progress: 32,
        last: 'interview.contribution-amount',
        next: 'interview.portfolio-preferences',
        step: 5,
        title: 'Interview - Risk Appetite',
        interviewStep: 1,
      },
      resolve: {
        completedRiskAppetiteQuizzes: [
          'CompletedRiskAppetiteQuiz',
          'currentUser',
          resolveCompletedRiskAppetiteQuizzes,
        ],
        header: [
          'completedRiskAppetiteQuizzes',
          function (completedRiskAppetiteQuizzes) {
            return completedRiskAppetiteQuizzes.length > 0
              ? 'Thank you for completing the quiz'
              : 'Tell us your risk appetite';
          },
        ],
        storedTrialRiskAppetite: ['TrialRiskAppetite', resolveStoredTrialRiskAppetite],
      },
    })
    .state('interview.portfolio-preferences', {
      url: '/portfolio-preferences',
      controller: 'InterviewPortfolioPreferencesController',
      hasMobileView: true,
      data: {
        progress: 40,
        last: 'interview.risk',
        next: 'optimizing',
        step: 6,
        title: 'Interview - Portfolio Preferences',
        header: 'Help us determine which portfolio fits you better',
        interviewStep: 1,
      },
      resolve: {
        completedPortfolioPreference: [
          'currentUser',
          'PortfolioPreferenceAnswer',
          resolvePortfolioPreference,
        ],
        currentAccount: [resolveCurrentAccount],
      },
    })
    .state('interview.account-credentials', {
      url: '/account',
      controller: 'AccountCredentialsController',
      data: {
        progress: 48,
        last: 'preview.investments',
        step: 7,
        title: 'Interview - Basic Information',
        header: 'Sign up to save your portfolio',
        interviewStep: 2,
      },
    });
}
