'use strict';

angular
  .module('controller.advise.account', [
    'service.activityDisplayHelper',
    'service.download-csv',
    'service.timeframeDisplayHelper',
    'service.user-notifications',
    'model.Activity',
    'model.Note',
    'model.Account',
    'model.PendingChange',
    'ram',
    'directive.advise.beneficiary-modify-card',
    'directive.advise.beneficiary-account-modify-all-card',
    'directive.editableContext',
    'directive.profile-form',
    'directive.upcoming-transfers-table',
    'directive.transfers-table',
    'service.init-unsaved-changes-tracking',
    'service.investment-charts.asset-allocation-data-generator',
    'service.investment-charts.holdings-data-generator',
    'service.beneficiary-service',
    'service.pending-changes-service',
    'service.form-modification-tracker',
    'service.account-service',
    'service.securityService',
    'component.advisor-dashboard.account-rif-detail',
    'component.beneficiary-account-display-all',
    'component.chart-set',
    'component.chart-set-period',
    'component.investment-charts.holdings-pie-chart',
    'component.investment-charts.model-portfolio-pie-chart',
    'component.investment-charts.holdings-pie-chart-legend',
  ])
  .directive('elemReady', [
    '$parse',
    '$timeout',
    function ($parse, $timeout) {
      return {
        restrict: 'A',
        link: function ($scope, elem, attrs) {
          $timeout(function () {
            elem.ready(function () {
              $scope.$apply(function () {
                var func = $parse(attrs.elemReady);
                func($scope);
              });
            });
          });
        },
      };
    },
  ])
  .directive('noTyping', [
    function () {
      return {
        restrict: 'A',
        link: function ($scope, elem) {
          elem.bind('keydown', function (e) {
            var ARROWS = [37, 38, 39, 40];
            if (
              ((e.metaKey || e.ctrlKey) && e.keyCode === 67) || // copy key
              _.contains(ARROWS, e.keyCode)
            ) {
              // arrows
              return;
            }
            e.preventDefault();
          });
        },
      };
    },
  ])
  .controller('AdviseAccountCtrl', [
    '$scope',
    '$filter',
    '$stateParams',
    '$window',
    '$state',
    '$timeout',
    'ram',
    'config',
    'Account',
    'Activity',
    'Note',
    'activityDisplayHelper',
    'client',
    'account',
    'accGoalAudits',
    'downloadCsv',
    'investmentChartsAssetAllocationDataGenerator',
    'investmentChartsHoldingsDataGenerator',
    'statements',
    'beneficiaries',
    'successorHolder',
    'accountHolders',
    'taxableAccounts',
    'bankAccounts',
    'unsavedChangesTracking',
    'formModificationTracker',
    'accountService',
    'timeframeDisplayHelper',
    'securityServiceFactory',
    'accountApprovals',
    'userNotifications',
    'invBarPrepData',
    'modals',
    adviseAccountController,
  ])
  .controller('AdviseBeneficiaryChangeController', [
    '$scope',
    '$q',
    'beneficiaryService',
    'formModificationTracker',
    'pendingChangesService',
    'PendingChange',
    adviseBeneficiaryChangeController,
  ])
  .controller('SuperAdviseBeneficiaryChangeController', [
    '$scope',
    '$q',
    'beneficiaryService',
    'pendingChangesService',
    'PendingChange',
    superAdviseBeneficiaryChangeController,
  ])
  .controller('PendingChangesController', [
    '$scope',
    'pendingChangesService',
    'PendingChange',
    pendingChangesController,
  ]);

function adviseAccountController(
  $scope,
  $filter,
  $stateParams,
  $window,
  $state,
  $timeout,
  ram,
  config,
  Account,
  Activity,
  Note,
  activityDisplayHelper,
  client,
  account,
  accGoalAudits,
  downloadCsv,
  investmentChartsAssetAllocationDataGenerator,
  investmentChartsHoldingsDataGenerator,
  statements,
  beneficiaries,
  successorHolder,
  accountHolders,
  taxableAccounts,
  bankAccounts,
  unsavedChangesTracking,
  formModificationTracker,
  accountService,
  timeframeDisplayHelper,
  securityServiceFactory,
  accountApprovals,
  userNotifications,
  invBarPrepData,
  modals
) {
  const platform = config.env.platform;
  $scope.isWsi = platform && platform.name === 'WSI';
  $scope.currentUserSecurity = securityServiceFactory.create($scope.$root.currentUser);

  $scope.client = client;
  $scope.accounts = client.accounts();
  $scope.account = account;
  $scope.taxableAccounts = taxableAccounts;
  $scope.bankAccounts = bankAccounts;
  $scope.accountApprovals = accountApprovals;
  $scope.canConvertToCorporate = $scope.account.canConvertToCorporate();

  $scope.isLira = function () {
    return config.types.Account[account.typeId()].name === 'lira';
  };

  $scope.isLockedInRrsp = function () {
    return config.types.Account[account.typeId()].name === 'locked_in_rrsp';
  };

  $scope.loadAccountSwitchModal = function () {
    modals.accountSwitchModal(account, client).result.then(function () {});
  };

  var hasTactical =
    $scope.account.accountGoals()[0].modelPortfolio().allocations()[0].tacticalWeight() !== null;

  $scope.investmentPreference =
    config.types.PortfolioPreference[$scope.account.investmentPreferenceId()]?.preview; // jshint ignore:line
  $scope.investmentPreferenceOption = $scope.investmentPreference || 'Not Available'; // jshint ignore:line

  $scope.isRecommendedPortfolioOption = $scope.account.recommendedPortfolioOptionId() !== null;

  const highInterestSavings = ['Save for a Rainy Day', 'Save for Short-term'];
  const isHighInterestSavings = highInterestSavings.includes(
    $scope.account.accountGoals()[0]?.goal().label()
  ); // jshint ignore:line
  const isHighInterestSavingsRecommendedAndChosen =
    isHighInterestSavings &&
    config.types.AccountPortfolioOption[$scope.account.portfolioOptionId()]?.label ===
      'High Interest Savings'; // jshint ignore:line

  $scope.recommendedPortfolioOption = isHighInterestSavings
    ? 'High Interest Savings'
    : config.types.AccountPortfolioOption[$scope.account.recommendedPortfolioOptionId()]?.label; // jshint ignore:line

  $scope.chosenIsSameAsRecommended = function () {
    if (isHighInterestSavingsRecommendedAndChosen) {
      return true;
    } else if (
      isHighInterestSavings &&
      $scope.account.portfolioOptionId() === $scope.account.recommendedPortfolioOptionId()
    ) {
      return false;
    } else {
      return $scope.account.portfolioOptionId() === $scope.account.recommendedPortfolioOptionId();
    }
  };

  var defaultPortfolioTypeSelection;
  if (hasTactical) {
    defaultPortfolioTypeSelection = 'tactical';
  } else {
    defaultPortfolioTypeSelection = 'strategic';
  }

  angular.extend($scope, {
    portfolioType: {
      options: [
        {
          label: 'Tactical',
          value: 'tactical',
        },
        {
          label: 'Strategic',
          value: 'strategic',
        },
      ],
      selection: new ram.Accessor(defaultPortfolioTypeSelection),
    },
  });

  function createStrategicLegendDataToDisp(chartDataList) {
    return _.map(chartDataList, function (chartDataRow) {
      return {
        color: chartDataRow.color,
        symbol: chartDataRow.allocation.fund().symbol(),
        label: chartDataRow.allocation.fund().label(),
        weight: chartDataRow.allocation.weight(),
      };
    });
  }

  function createTacticalLegendDataToDisp(chartDataList) {
    return _.map(chartDataList, function (chartDataRow) {
      return {
        color: chartDataRow.color,
        symbol: chartDataRow.allocation.fund().symbol(),
        label: chartDataRow.allocation.fund().label(),
        weight: chartDataRow.allocation.tacticalWeight(),
      };
    });
  }

  const strategicChartData = investmentChartsAssetAllocationDataGenerator.getData(
    $scope.account.accountGoals()[0].modelPortfolio().nonZeroAllocations()
  );
  const strategicLegendData = createStrategicLegendDataToDisp(strategicChartData);
  const allocationChartDataStrategic = invBarPrepData.getChartDataForAllocations(
    strategicChartData.map((x) => x.allocation)
  );
  $scope.allocationDataForLegend = strategicLegendData;
  if (allocationChartDataStrategic) {
    $scope.allocationToBeStacked = allocationChartDataStrategic.toBeStacked;
    $scope.allocationsChartData = allocationChartDataStrategic.investmentsChartData;
  }

  $scope.canSelectPortfolioType = hasTactical;

  let tacticalLegendData;
  let allocationChartDataTactical;

  if (hasTactical) {
    const tacticalChartData = investmentChartsAssetAllocationDataGenerator.getData(
      $scope.account.accountGoals()[0].modelPortfolio().nonZeroTacticalAllocations()
    );
    tacticalLegendData = createTacticalLegendDataToDisp(tacticalChartData);
    allocationChartDataTactical = invBarPrepData.getChartDataForTacticalAllocations(
      tacticalChartData.map((x) => x.allocation)
    );
  }

  $scope.$watchGroup(['portfolioType.selection()'], handlePortfolioTypeSelect);

  function handlePortfolioTypeSelect(values) {
    if (values[0] === 'strategic') {
      $scope.allocationDataForLegend = strategicLegendData;
      if (allocationChartDataStrategic) {
        $scope.allocationToBeStacked = allocationChartDataStrategic.toBeStacked;
        $scope.allocationsChartData = allocationChartDataStrategic.investmentsChartData;
      }
    } else {
      $scope.allocationDataForLegend = tacticalLegendData;
      if (allocationChartDataTactical) {
        $scope.allocationToBeStacked = allocationChartDataTactical.toBeStacked;
        $scope.allocationsChartData = allocationChartDataTactical.investmentsChartData;
      }
    }
  }

  $scope.nonZeroPositions = investmentChartsHoldingsDataGenerator.getNonAggregateData(
    $scope.account.nonZeroPositions(),
    $scope.account.accountGoals()[0].modelPortfolio()
  );
  if ($scope.nonZeroPositions && $scope.nonZeroPositions.length > 0) {
    const invChartDataObject = invBarPrepData.getChartDataForPositions($scope.nonZeroPositions);
    if (invChartDataObject) {
      $scope.toBeStacked = invChartDataObject.toBeStacked;
      $scope.investmentsChartData = invChartDataObject.investmentsChartData;
    }
    $scope.bookValueBalance = $scope.nonZeroPositions
      .map((pos) => parseFloat(pos.bookValue()))
      .reduce((a, b) => a + b);
  }

  $scope.loadBarChart = () => {
    // This is to force the chart to reload. Because it is hidden when the page loads due
    // to being on a tab, the chart would not show at all. This was due to the width being
    // 'auto' instead of a pixel value when the chart is hidden.
    // This forces a redraw after the Portfolio tab is displayed.
    $timeout(() => {
      $scope.forceRedraw = {
        force: true,
      };
    }, 50);
  };

  $scope.isResp = accountService.isResp(account);
  $scope.canHaveBeneficiaries = accountService.canHaveBeneficiaries(account);
  $scope.isFamilyResp = accountService.isFamilyResp($scope.account);
  $scope.statements = statements;
  $scope.beneficiaries = beneficiaries;
  $scope.beneficiary = _.first(beneficiaries);
  $scope.successorHolder = successorHolder;
  $scope.canAddBeneficiary =
    $scope.isFamilyResp || ($scope.isResp && !_.first($scope.beneficiaries));
  $scope.accountHolders = accountHolders;

  $scope.mutateBeneficiaries = function (beneficiaries) {
    $scope.beneficiaries = beneficiaries;
  };

  $scope.mutateSuccessorHolder = function (successorHolder) {
    $scope.successorHolder = successorHolder;
  };

  $scope.loadRiskModal = function () {
    $scope.advisorModifyAccountRiskModal($scope.account);
  };

  $scope.loadPortfolioOptionsModal = function () {
    $scope.advisorModifyPortfolioOptionsModal($scope.account);
  };

  $scope.loadActivityNotesModal = function (activityId) {
    Activity.find({
      id: activityId,
    }).then(function (activity) {
      $scope.genericMessageModal(
        'Advisor Notes - ' + $filter('date')(activity.createdAt(), 'medium'),
        activity.notes()
      );
    });
  };

  $scope.loadRebalancingModal = function () {
    $scope
      .advisorModifyRebalancingOptionModal($scope.account.skipRebalancing())
      .result.then(function () {
        $scope.account.changeRebalancing();
      });
  };

  $scope.loadClientNameOverrideModal = function () {
    $scope.advisorModifyClientNameOverrideModal($scope.account).result.then(function () {
      $scope.account.save();
    });
  };

  $scope.loadJurisdictionModal = function () {
    $scope.advisorModifyJurisdictionModal($scope.account);
  };

  $scope.goalChangers = function () {
    var changers = {};

    accGoalAudits.forEach(function (audit) {
      var goalChangerId = audit.userId();
      if (!goalChangerId || changers[goalChangerId]) {
        return;
      }

      // Assuming an advisor(who is not super advisor) cannot change client's risk level.
      if (account.userId() === goalChangerId) {
        changers[goalChangerId] = 'Client';
      } else if ($scope.currentUserSecurity.isSuperAdvisor()) {
        changers[goalChangerId] = audit.userFullName() + ' (advisor)';
      } else {
        changers[goalChangerId] = 'ModernAdvisor';
      }
    });

    return changers;
  };

  $scope.balancePeriods = [
    {
      label: 'All',
    },
    {
      label: '1 Year',
      start: moment().subtract(1, 'years').toDate(),
    },
    {
      label: 'YTD',
      start: moment().startOf('years').toDate(),
    },
    {
      label: '3 Months',
      start: moment().subtract(3, 'months').toDate(),
    },
    {
      label: '1 Month',
      start: moment().subtract(1, 'months').toDate(),
    },
    {
      label: 'Custom',
    },
  ];

  $scope.balancePeriod = new ram.Accessor($scope.balancePeriods[0]);

  $scope.activityTypes = activityDisplayHelper.getActivityTypes();
  $scope.activityPeriods = timeframeDisplayHelper.getPeriods();

  $scope.activityType = new ram.Accessor($scope.activityTypes[0]);
  $scope.activityPeriod = new ram.Accessor($scope.activityPeriods[0]);

  $scope.activities = activityDisplayHelper.activities;
  $scope.accountById = activityDisplayHelper.accountById;

  $scope.$watchGroup(['activityType()', 'activityPeriod()'], function () {
    $scope.allActivities = activityDisplayHelper.activities(
      $scope.account,
      $scope.activityType(),
      $scope.activityPeriod()
    );
  });

  /**
   * This is simple now, but in the future will obtain the advisor's name
   * @param  {integer} userId The user ID who made the change for Modify activity
   * @return {string }        For now, 'client' or 'advisor'
   */
  $scope.lookupUserName = function (userId) {
    if (userId === account.userId) {
      return 'Client';
    } else {
      return 'Advisor';
    }
  };

  $scope.downloadBalances = downloadCsv.balances;

  $scope.convertToCorporate = function () {
    var modalTitle = 'Convert Account To Corporate';
    var modalBody = 'Are you sure you want to convert this account to Corporate?';

    userNotifications.clearNotification();
    $scope.$root
      .genericConfirmationModal(modalTitle, modalBody, 'btn-danger', 'Convert')
      .result.then(() => $scope.account.convertToCorporate())
      .then(() => {
        return $scope.account.reload({
          force: true,
        });
      })
      .then(() => {
        $scope.canConvertToCorporate = $scope.account.canConvertToCorporate();
        userNotifications.showTransientSuccess('Success.  Account converted to Corporate.');
      })
      .catch((error) => {
        if (error) {
          userNotifications.showError('There was an error.  Please contact support.');
        }
      });
  };

  /****************************************************
   * Advisor Notes Code
   */
  var MIN_TEXTAREA_HEIGHT = 95;

  $scope.newNote = new ram.Accessor('');
  $scope.existingNotes = account.notes();

  function setTextAreaHeight(height, e) {
    var element = typeof e === 'object' ? e.target : document.getElementById('advisor-new-note');
    element.style.height = height + 'px';
  }

  $scope.autoExpand = function (e) {
    var heights = [MIN_TEXTAREA_HEIGHT];
    var element = typeof e === 'object' ? e.target : document.getElementById('advisor-new-note');
    heights.push(element.scrollHeight);
    setTextAreaHeight(_.max(heights), e);
  };

  $scope.resetNoteForm = function () {
    $scope.newNote('');
    setTextAreaHeight(MIN_TEXTAREA_HEIGHT);
  };

  $scope.saveNote = function () {
    Note.create({
      accountId: $scope.account.id,
      text: $scope.newNote(),
    }).then(
      function (note) {
        $scope.existingNotes.unshift(note);
        $scope.resetNoteForm();
        $timeout(setHeightBelowForAll, 50);
      },
      function () {}
    );
  };

  $scope.setHeightBelow = function (index) {
    var box = document.getElementById('textarea-' + index);
    var height = box.scrollHeight;
    box.style.height = height + 'px';
  };

  function setHeightBelowForAll() {
    _.each($scope.existingNotes, function (_note, index) {
      $scope.setHeightBelow(index);
    });
  }

  const transferInstructionsContainerUpcoming = {
    transferInstructions: null,
  };
  const transferInstructionsContainerInactive = {
    transferInstructions: null,
  };

  const isTransferInstructionEndDate7DaysOld = function (transferInstruction) {
    if (transferInstruction.transferRequests().length === 0) {
      return false;
    }

    if (!transferInstruction.hasCreatedAllTransferRequests()) {
      return false;
    }

    const mostRecentTransferRequest = _.chain(transferInstruction.transferRequests())
      .sortBy(function (transferRequest) {
        return transferRequest.dateToBeProcessed();
      })
      .last()
      .value();

    const endDate = moment(mostRecentTransferRequest.dateToBeProcessed()).toDate();

    return endDate < moment().subtract(7, 'days').toDate();
  };

  $scope.transferInstructionsContainerUpcoming = transferInstructionsContainerUpcoming;
  $scope.transferInstructionsContainerInactive = transferInstructionsContainerInactive;

  $scope.$watchCollection('account.transferInstructions()', function () {
    if (account.transferInstructions()) {
      const upcoming = account.transferInstructions().filter((x) => {
        return (
          !x.cancelled() && !x.status.is.finished() && !isTransferInstructionEndDate7DaysOld(x)
        );
      });
      const inactive = account.transferInstructions().filter((x) => {
        return (
          !x.frequency.is.onlyOnce() &&
          (x.cancelled() || x.status.is.finished()) &&
          x.transferRequests().length > 0
        );
      });

      // I'm leaving the transfers-table dumb so it just displays what it gets in whatever
      // order it is in. That way I can use the same component for both the upcoming
      // and inactive transfers. It could be done by passing in a flag to the directive too
      // but I did it this way instead. Future generations can decide if my decision
      // was right or wrong.
      transferInstructionsContainerUpcoming.transferInstructions = upcoming;
      transferInstructionsContainerInactive.transferInstructions = inactive.sort((a, b) => {
        return b.startDate().toString().localeCompare(a.startDate().toString());
      });
    }
  });

  /**************************************************
   * Beneficiary
   */
  $scope.beneficiaryTabLabel = beneficiaries.length <= 1 ? 'Beneficiary' : 'Beneficiaries';

  /**************************************************
   * Switching tabs
   */
  var BALANCE_HISTORY = 0;
  var BENEFICIARY = 6;
  var NOTES = 7;
  var RIFPAYMENT = 8;
  if ($stateParams.initialTab === 'payment') {
    $scope.active = RIFPAYMENT;
  } else if ($stateParams.initialTab === 'notes') {
    $scope.active = NOTES;
  } else {
    $scope.active = BALANCE_HISTORY;
  }

  var unsavedMessageForPage = {
    mandatory: true,
    messageTitle: 'Inconsistent Data',
    messageBody:
      '<p>Your data on the Account page is incomplete.</p><p>Please Cancel or enter all data and press Save.</p>',
  };
  unsavedChangesTracking.init($scope.$root, $scope, $window, $state, unsavedMessageForPage);

  function createUnsavedMessageForTab(tabName) {
    return {
      mandatory: true,
      messageTitle: 'Inconsistent Data',
      messageBody:
        '<p>Your data on the ' +
        tabName +
        ' tab is incomplete.</p><p>Please Cancel or enter all data and press Save.</p>',
    };
  }

  function showUnsavedModal(tabName, oldTabNum) {
    var unsavedMessage = createUnsavedMessageForTab(tabName);
    $scope.genericOkModal(unsavedMessage.messageTitle, unsavedMessage.messageBody);
    $scope.active = oldTabNum;
  }

  $scope.$watch('active', function (newVal, oldVal) {
    if (formModificationTracker.hasUnsavedChanged() && newVal) {
      var formName = formModificationTracker.getCurrentForm().formName;

      if (newVal !== BENEFICIARY && oldVal === BENEFICIARY && formName === 'form') {
        showUnsavedModal('Beneficiary', BENEFICIARY);
      } else if (newVal !== RIFPAYMENT && oldVal === RIFPAYMENT && formName === 'rifDetailForm') {
        showUnsavedModal('Payment Details', RIFPAYMENT);
      }
    }

    if (newVal && newVal === NOTES) {
      // no else
      $timeout(setHeightBelowForAll, 50); // 1 works, 50 is generous. The usual visibility tricks don't work here because the notes are always in the DOM, even when not shown.
    }
  });
}

function adviseBeneficiaryChangeController(
  $scope,
  $q,
  beneficiaryService,
  formModificationTracker,
  pendingChangesService,
  PendingChange
) {
  /* jshint validthis: true */
  function reload() {
    var _beneficiaries;
    var _account;
    return $scope.account.fetch(true).then(function (account) {
      _account = account;
      return account.beneficiaries
        .ready(true)
        .then(function (beneficiaries) {
          _beneficiaries = beneficiaries;
          return $q.all(
            _.map(beneficiaries, function (beneficiary) {
              return beneficiary.loadDependantObjects();
            })
          );
        })
        .then(function () {
          return _account.successorHolder.ready();
        })
        .then(function () {
          return $scope.client.person().personalRelations.ready(true);
        })
        .then(function () {
          var _successorHolder =
            _account.successorHolder() && _account.successorHolder().id
              ? _account.successorHolder()
              : null;
          beneficiaryService.updateScopeBeneficiaries(_beneficiaries, _successorHolder);
          pendingChangesService
            .getPendingBeneficiaryDesignations($scope.account.id)
            .then(function (response) {
              const pendingBeneficiariesData = response.data.map((pc) => PendingChange.new(pc));
              const accountScope = pendingChangesService.getBaseScope();
              if (accountScope) {
                accountScope.pendingBeneficiariesData = pendingBeneficiariesData;
                $scope.pendingBeneficiariesData = pendingBeneficiariesData;
              }
            });
        });
    });
  }

  this.editError = function () {
    return beneficiaryService.getEditError($scope.account);
  };

  this.save = function (id) {
    var beneficiary = _.find($scope.beneficiaries, function (_beneficiary) {
      return _beneficiary.id === id;
    });
    beneficiary = beneficiary || $scope.successorHolder;
    if (beneficiary) {
      beneficiary.justCreated(false);
      $scope.preventEdit = false;
      return beneficiaryService.save(beneficiary).then(function () {
        return reload();
      });
    } else {
      return reload();
    }
  };

  this.reset = function (id) {
    if (id) {
      var beneficiary = _.find($scope.beneficiaries, function (_beneficiary) {
        return _beneficiary.id === id;
      });
      beneficiary = beneficiary || $scope.successorHolder;
      if (beneficiary && beneficiary.justCreated()) {
        beneficiaryService.removeRespBeneficiary(beneficiary);
      }
    }
    $scope.preventEdit = false;
    return reload();
  };

  /**
   * The caller may wish to control the isSaving flag.
   */
  $scope.getForeignScope = function (foreignScope) {
    $scope.foreignScope = foreignScope;
  };

  $scope.addBeneficiary = function () {
    beneficiaryService.setAccount($scope.account);
    beneficiaryService.addRespBeneficiary();
  };

  $scope.$watch('beneficiaries.length', function (newVal, oldVal) {
    // The following gets picked up by getPreventEdit
    $scope.preventEdit =
      !$scope.preventEdit &&
      newVal !== undefined &&
      newVal > oldVal &&
      _.find($scope.beneficiaries, function (beneficiary) {
        return beneficiary.justCreated();
      });
    $scope.canAddBeneficiary =
      $scope.isFamilyResp || ($scope.isResp && !_.first($scope.beneficiaries));
  });

  $scope.disableCreateNewBeneficiary = function () {
    var editingAnother = formModificationTracker.hasUnsavedChanged();
    var justCreatedNew = _.find($scope.beneficiaries, function (beneficiary) {
      return beneficiary.justCreated();
    });
    return editingAnother || justCreatedNew;
  };

  beneficiaryService.setBaseScope($scope);

  $scope.$on('pending-beneficiary-changed', (_, data) => {
    $scope.pendingBeneficiariesData = data;
  });
}

function superAdviseBeneficiaryChangeController(
  $scope,
  $q,
  beneficiaryService,
  pendingChangesService,
  PendingChange
) {
  /* jshint validthis: true */
  function reload(_this) {
    var _beneficiaries;
    var _account;
    return $scope.account.fetch(true).then(function (account) {
      _account = account;
      return account.beneficiaries
        .ready(true)
        .then(function (beneficiaries) {
          _beneficiaries = beneficiaries;
          return $q.all(
            _.map(beneficiaries, function (beneficiary) {
              return beneficiary.loadDependantObjects();
            })
          );
        })
        .then(function () {
          return _account.successorHolder.ready(true);
        })
        .then(function () {
          return $scope.client.person().personalRelations.ready(true);
        })
        .then(function () {
          var _successorHolder =
            _account.successorHolder() && _account.successorHolder().id
              ? _account.successorHolder()
              : null;
          _this.childCtrl.callback('mutate-data', _beneficiaries, _successorHolder);
          beneficiaryService.updateScopeBeneficiaries(_beneficiaries, _successorHolder);
          beneficiaryService.getScope().$broadcast('beneficiary-edit-complete'); // to reload
          pendingChangesService
            .getPendingBeneficiaryDesignations($scope.account.id)
            .then(function (response) {
              const pendingBeneficiariesData = response.data.map((pc) => PendingChange.new(pc));
              const accountScope = pendingChangesService.getBaseScope();
              if (accountScope) {
                accountScope.pendingBeneficiariesData = pendingBeneficiariesData;
                $scope.pendingBeneficiariesData = pendingBeneficiariesData;
              }
            });
        });
    });
  }

  this.editError = function () {
    return beneficiaryService.getEditError($scope.account);
  };

  this.register = function (child) {
    this.childCtrl = child;
  };

  this.preSaveUnbound = function () {
    return this.childCtrl.callback('validate');
  };
  this.preSave = this.preSaveUnbound.bind(this);

  this.save = function () {
    const promise = this.childCtrl.callback('save'); // This will always be valid because this controller wraps the child component.
    return promise.then(() => {
      return reload(this);
    });
  };

  this.reset = function () {
    // All JS operations are done in memory.  To reset, one only needs to throw it all away.
    this.childCtrl.callback('reset');
    const defer = $q.defer();
    defer.resolve();
    return defer.promise;
  };

  /**
   * The caller may wish to control the isSaving flag.
   */
  $scope.getForeignScope = function (foreignScope) {
    $scope.foreignScope = foreignScope;
  };

  beneficiaryService.setBaseScope($scope);

  $scope.$on('pending-beneficiary-changed', (_, data) => {
    $scope.pendingBeneficiariesData = data;
  });
}

function pendingChangesController($scope, pendingChangesService, PendingChange) {
  pendingChangesService
    .getPendingBeneficiaryDesignations($scope.account.id)
    .then(function (response) {
      $scope.pendingBeneficiariesData = response.data.map((pc) => PendingChange.new(pc));
      $scope.$root.$broadcast('pending-beneficiary-changed', $scope.pendingBeneficiariesData);
    });

  $scope.loadDeletePendingBeneficiaryDesignationsModal = function () {
    $scope.deletePendingBeneficiaryDesignationsModal(
      pendingChangesService,
      $scope.pendingBeneficiariesData[0].id,
      $scope.$root
    );
  };

  $scope.pendingBeneficiaryLabel = function (beneficiary) {
    return `${beneficiary.firstName}${
      beneficiary.middleName ? ` ${beneficiary.middleName} ` : ' '
    }${beneficiary.lastName}`;
  };

  $scope.pendingBeneficiaryRelationship = function (beneficiary) {
    if (this.config.types.PersonalRelation[beneficiary.personalRelationTypeId].name === 'other') {
      return `${this.config.types.PersonalRelation[beneficiary.personalRelationTypeId].label} (${
        beneficiary.otherRelationName
      })`;
    } else {
      return `${this.config.types.PersonalRelation[beneficiary.personalRelationTypeId].label}`;
    }
  };

  $scope.pendingBeneficiaryPercentage = function (beneficiary) {
    return `${beneficiary.percent}%`;
  };

  pendingChangesService.setBaseScope($scope);
}
