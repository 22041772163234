'use strict';

angular
  .module('controller.modal.advisor.confirm-account-approval', ['ram'])
  .controller('ModalAdvisorConfirmAccountApprovalCtrl', ['$scope', 'ram', controller]);

function controller($scope, ram) {
  $scope.identityVerified = new ram.Accessor(true);
  $scope.naafSigned = new ram.Accessor(true);
  $scope.imaSigned = new ram.Accessor(true);
  $scope.validIdOnFile = new ram.Accessor(false);
}
