'use strict';

class AdvisorDisclosureFormController {
  constructor($scope, ram, PortfolioManager) {
    this.$scope = $scope;
    this.ram = ram;
    this.PortfolioManager = PortfolioManager;
  }

  /* jshint ignore: start */
  defaultDisclosures = {
    single: {
      advisorReferral: 'I acknowledge that I was referred to ModernAdvisor by {{advisorName}}',
      advisorNotPartnerOfMa:
        'I understand that {{advisorName}} is not an employee, agent, or partner of ModernAdvisor',
      advisorReceivesServiceFee:
        'I acknowledge that {{advisorName}} will receive a service fee out of the assets held in my ModernAdvisor accounts',
      shareInfo:
        'I consent to ModernAdvisor sharing my account and personal information with {{advisorName}}',
    },
    withInstitution: {
      advisorReferral: 'I acknowledge that I was referred to ModernAdvisor by {{institutionName}}',
      advisorNotPartnerOfMa:
        'I understand that neither {{institutionName}} nor {{advisorName}} is an employee, agent, or partner of ModernAdvisor',
      advisorReceivesServiceFee:
        'I acknowledge that {{institutionName}} will receive a service fee out of the assets held in my ModernAdvisor accounts',
      shareInfo:
        'I consent to ModernAdvisor sharing my account and personal information with {{advisorName}} and {{institutionName}}',
    },
    withoutPersonName: {
      advisorReferral: 'I acknowledge that I was referred to ModernAdvisor by {{institutionName}}',
      advisorNotPartnerOfMa:
        'I understand that {{institutionName}} is not an employee, agent, or partner of ModernAdvisor',
      advisorReceivesServiceFee:
        'I acknowledge that {{institutionName}} will receive a service fee out of the assets held in my ModernAdvisor accounts',
      shareInfo:
        'I consent to ModernAdvisor sharing my account and personal information with {{institutionName}}',
    },
  };

  legacyDisclosures = {
    'BlackBurn Davis': {
      advisorReferral:
        'I acknowledge that I was referred to ModernAdvisor by Aligned Capital Partners through one of their representatives at BlackBurn Davis Financial Inc.',
      advisorNotPartnerOfMa:
        'I understand that neither Aligned Capital Partners nor BlackBurn Davis Financial Inc. nor any of their employees are agents, partners, or employees of ModernAdvisor',
      advisorReceivesServiceFee:
        'I acknowledge that BlackBurn Davis Financial will receive a service fee out of the assets held in my ModernAdvisor accounts',
      shareInfo:
        'I consent to ModernAdvisor sharing my account and personal information with BlackBurn Davis Financial Inc. and Aligned Capital Partners Inc.',
    },
    'Tyler Pfeiffer': {
      advisorReferral:
        'I acknowledge that I was referred to ModernAdvisor by Aligned Capital Partners Inc. through one of their representatives at First Foundation Financial Inc.',
      advisorNotPartnerOfMa:
        'I understand that neither Aligned Capital Partners nor First Foundation Financial nor any of their employees are agents, partners, or employees of ModernAdvisor',
      advisorReceivesServiceFee:
        'I acknowledge that First Foundation Financial will receive a service fee out of the assets held in my ModernAdvisor accounts',
      shareInfo:
        'I consent to ModernAdvisor sharing my account and personal information with First Foundation Financial and Aligned Capital Partners',
    },
    'Paul MacMunn': {
      advisorReferral:
        'I acknowledge that I was referred to ModernAdvisor by Aligned Capital Partners Inc. through one of their representatives at Wealth In Place Inc.',
      advisorNotPartnerOfMa:
        'I understand that neither Aligned Capital Partners nor Wealth In Place Inc. nor any of their employees are agents, partners, or employees of ModernAdvisor',
      advisorReceivesServiceFee:
        'I acknowledge that Wealth In Place Inc. will receive a service fee out of the assets held in my ModernAdvisor accounts',
      shareInfo:
        'I consent to ModernAdvisor sharing my account and personal information with Wealth In Place Inc. and Aligned Capital Partners',
    },
  };
  /* jshint ignore: end */

  $onInit() {
    this.acknowledgedAdvisorReferral = new this.ram.Accessor(false);
    this.acknowledgedAdvisorNotPartnerOfMa = new this.ram.Accessor(false);
    this.acknowledgedAdvisorReceivesServiceFee = new this.ram.Accessor(false);
    this.acknowledgedSharingPersonalInfoWithAdvisor = new this.ram.Accessor(false);
    this.acknowledgedMASubsidiaryOfGuardianCapital = new this.ram.Accessor(false);

    this.keys = ['advisorReferral', 'advisorNotPartnerOfMa', 'shareInfo'];

    /* Note - When the back-end is ready, it will supply the special disclosures via controller endpoints.  They will be in `this.disclosures`.
     *        - '/api/advisor_referral_details/' + externalAdvisorLink + '.json'
     *        - '/api/advisor_create_new_client_flows/' + $stateParams.advisorNewClientFlowPassword + '/sanitized_details'
     *        These two endpoints collect the data that feeds the disclosures page.  It depends on where you are starting from which path is
     *        followed.
     */
    this.displayText = this.legacyDisclosures[this.advisorName] ?? {};

    if (this.showGroupUnderOneName) {
      // Such as InvestorDNA
      this.defaultDisclosures = this.defaultDisclosures.withoutPersonName;
    } else if (this.institutionName) {
      this.defaultDisclosures = this.defaultDisclosures.withInstitution;
    } else {
      this.defaultDisclosures = this.defaultDisclosures.single;
    }

    if (![...this.keys, 'advisorReceivesServiceFee'].every((key) => this.displayText[key])) {
      this.keys.forEach((key) => {
        this.displayText[key] =
          (this.disclosures && this.disclosures[key]) ?? this.defaultDisclosures[key];
      });

      if (this.hasServiceFee) {
        this.displayText.advisorReceivesServiceFee =
          this.disclosures?.advisorReceivesServiceFee ??
          this.defaultDisclosures.advisorReceivesServiceFee;
      }
    }

    if (this.preferredPortfolioManagerId === this.PortfolioManager.constructor.guardianId()) {
      this.displayText.guardianReferred =
        this.disclosures?.guardianReferred ?? this.defaultDisclosures.guardianReferred;
    }

    if (!this.hasServiceFee) {
      this.displayText.advisorReceivesServiceFee = null;
    }
    for (const key in this.displayText) {
      if (this.displayText.hasOwnProperty(key)) {
        this.displayText[key] = this.textSubstitute(this.displayText[key]);
      }
    }
  }

  textSubstitute(text) {
    return text
      ?.replace(/{{advisorName}}/g, this.advisorName)
      ?.replace(/{{institutionName}}/g, this.institutionName);
  }
}

export default AdvisorDisclosureFormController;
